@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --rt-opacity: 1 !important;
}

html {
  font-size: 14px;
}

h2,
h3 {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  pointer-events: none;
}

svg {
  vertical-align: top;
}

.cutoff:before {
  content: '';
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 5' style='background-color: black;'%3E%3Cpolygon points='0,5 5.5,0 11,5' fill='white' /%3E%3C/svg%3E");
  background-repeat: repeat-x;
}

.hide-arrow[type='number']::-webkit-inner-spin-button,
.hide-arrow[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#sport_div_iframe {
  height: 100%;
  min-height: 90vh;
  overflow: auto;
}

#sport_div_iframe > iframe {
  height: 100%;
}

#betslip-container-element {
  z-index: 9999 !important;
}
 iframe#launcher, iframe#launcher + iframe  {
   visibility: hidden;
 }
 
 @media screen and (min-width: 980px) {
   iframe#launcher, iframe#launcher + iframe {
     visibility: visible;
   }
 }


.bullet, .bullet>li {
  list-style: unset;
  margin: unset;
}

.number, .number>li {
  list-style: auto;
  margin: unset;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.email, .phone {
  text-decoration: none;
  -webkit-text-decoration-skip: none;
}
.react-tooltip {
  z-index: 1;
}